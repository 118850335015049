import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationUA from './locales/ua/translation'

i18n
    .use(initReactI18next)
    .init({
        debug:false,
        caches: [],
        resources: {
            ua: {
                translation: translationUA
            }
        },
        lng: "ua",
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;