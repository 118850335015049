import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import {createStore, combineReducers}  from 'redux'
import {Provider} from 'react-redux'
import './css/lib/bootstrap-reboot.css';
import './css/lib/bootstrap-grid.css';
import './css/lib/bootstrap-custom.css';
import './i18n';
// import * as serviceWorker from './serviceWorker';
import userReducer from './store/reducers/user'
import problemReducer from './store/reducers/trackerProblems'
import alertReducer from './store/reducers/alert'
import merchantReducer from './store/reducers/merchant'

const appReducer = combineReducers({
    usr: userReducer,
    prblm: problemReducer,
    alert: alertReducer,
    mrcht:merchantReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
};

const store = createStore(rootReducer);

ReactDOM.render(
    <Provider store={store}>
    <App/>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
