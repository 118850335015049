import React, {Component} from 'react';
import st from './iOs.module.css'
import cn from 'classnames';
import {withTranslation} from 'react-i18next';
import mainStyle from "../../../css/main.module.css";
import LiWithArrow from "../../LiWithArrow/LiWithArrow";

class iOs extends Component {
    state = {
        characteristics: false,
        download: false,
        registration: false,
        firstPay: false,
        instruction: false
    };

    toggleShow = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    };

    render() {
        const {characteristics, download, registration, firstPay, instruction} = this.state;
        const {t} = this.props;
        return (
            <>
                <header>
                    <div className={cn(mainStyle.header, "col-xl-6 offset-xl-3")}>
                        <span>{t('mobileApp.title')}</span>
                    </div>
                </header>

                <div className='row mt-3'>
                    <div className='col-12  col-xl-6 offset-xl-3 mb-2'>
                        {t('mobileApp.setting.iOs.title')}
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-xl-6 offset-xl-3'>
                        <ul className={st.terminal_list}>
                            <LiWithArrow click={this.toggleShow} elementName={"characteristics"}
                                         elementText={t("mobileApp.setting.iOs.characteristics.title")}/>
                            {
                                characteristics ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                1. {t('mobileApp.setting.iOs.characteristics.1')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                2. {t('mobileApp.setting.iOs.characteristics.2')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                3. {t('mobileApp.setting.iOs.characteristics.3')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                4. {t('mobileApp.setting.iOs.characteristics.4')}
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"download"}
                                         elementText={t('mobileApp.setting.iOs.download.title')}/>
                            {
                                download ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                {t('mobileApp.setting.iOs.download.1')} - <a
                                                href="https://apps.apple.com/ua/app/%D1%82%D0%B5%D1%80%D0%BC%D1%96%D0%BD%D0%B0%D0%BB/id6450216954"
                                                target="_blank"  rel="noopener noreferrer">App Store</a>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"registration"}
                                         elementText={t('mobileApp.setting.iOs.registration.title')}/>
                            {
                                registration ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                {t('mobileApp.setting.iOs.registration.1')} <a
                                                href="https://youtu.be/amqJMhW4EgM"
                                                target="_blank"  rel="noopener noreferrer">{t('mobileApp.setting.iOs.registration.2')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"firstPay"}
                                         elementText={t('mobileApp.setting.iOs.firstPay.title')}/>
                            {
                                firstPay &&
                                <div className={cn(st.text, 'ml-1, mb-3')}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            {t('mobileApp.setting.iOs.firstPay.1')} <a
                                            href="https://youtu.be/J5FAV8j9xjI"
                                            target="_blank"
                                            rel="noopener noreferrer">{t('mobileApp.setting.iOs.firstPay.2')}</a>
                                        </div>
                                    </div>
                                </div>
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"instruction"}
                                         elementText={t('mobileApp.setting.iOs.instruction.title')}/>
                            {
                                instruction &&
                                <div className={cn(st.text, 'ml-1, mb-3')}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            {t('mobileApp.setting.iOs.instruction.1')} <a
                                            href="https://privatbank.ua/business/instruction-terminal/ios"
                                            target="_blank"
                                            rel="noopener noreferrer">{t('mobileApp.setting.iOs.instruction.2')}</a>
                                        </div>
                                    </div>
                                </div>
                            }
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(iOs);
