const initialState = {
    isLoadMerchantInfo:false,
    merchantInfo:[]
};

const merchant = (state = initialState, action) => {
    switch (action.type) {
        case 'START_LOADING_MERCHANT':
            return {
                ...state,
                isLoadMerchantInfo: true
            };
        case 'STOP_LOADING_MERCHANT':
            return {
                ...state,
                isLoadMerchantInfo: false
            };
        case 'SET_MERCHANT':
            return {
                ...state,
                merchantInfo: action.payload,
                isLoadMerchantInfo:false
            };
        default:
            return state;
    }
};

export default merchant;