import React, {Component} from 'react';
import cn from "classnames";
import st from "./LiWithArrow.module.css";
import arrowRight from "../../img/triple_arrow_right.svg";
import arrowBottom from "../../img/triple_arrow_bottom.svg";

class LiWithArrow extends Component {
    state = {
        active:false
    };

    handleClick = (e) => {
        const {name} = e.currentTarget.dataset;
        this.props.click(name);
        this.setState({
            active:!this.state.active
        })
    };

    render() {
        const {active} = this.state;
        const {elementName, elementText} = this.props;
        return (
            <li onClick={this.handleClick}
                data-name= {elementName}
                className={cn('col-12', st.li_style, {[st.active]: active})}>
                <span className='col-8'> {elementText} </span>
                {!active ? <img src={arrowRight} alt=""/> : <img src={arrowBottom} alt=""/>}
            </li>
        )
    }
}

export default LiWithArrow;