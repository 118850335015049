import React from 'react';
import st from './MobileAppTerminalFullMenu.module.css'
import {Link} from "react-router-dom";
import cn from "classnames";
import mainStyle from "../../../css/main.module.css";
import {useTranslation} from 'react-i18next';

const MobileAppTerminalFullMenu = () => {
    const {t} = useTranslation();
    return (
        <>
            <header>
                <div className={cn(mainStyle.header, "col-xl-6 offset-xl-3")}>
                    <span>{t('mobileApp.title')}</span>
                </div>
            </header>

            <div className='row mt-3'>
                <div className='col-6 col-xl-3 offset-xl-3'>
                    <Link to='/mobile-app-terminal/about'>
                        <button className={st.section}>
                            {t('mobileApp.about.title')}
                        </button>
                    </Link>
                </div>
                <div className='col-6 col-xl-3 '>
                    <Link to='/mobile-app-terminal/functions'>
                        <button className={st.section}>
                            {t('mobileApp.functions.title')}
                        </button>
                    </Link>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-6 col-xl-3 offset-xl-3'>
                    <Link to='/mobile-app-terminal/ios'>
                        <button className={st.section}>
                            {t('mobileApp.setting.iOs.title')}
                        </button>
                    </Link>
                </div>
                <div className='col-6 col-xl-3 '>
                    <Link to='/mobile-app-terminal/android'>
                        <button className={st.section}>
                            {t('mobileApp.setting.android.title')}
                        </button>
                    </Link>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-6 col-xl-3 offset-xl-3'>
                    <Link to='/mobile-app-terminal/other'>
                        <button className={st.section}>
                            {t('mobileApp.other.title')}
                        </button>
                    </Link>
                </div>
            </div>
        </>
    )
};

export default MobileAppTerminalFullMenu;