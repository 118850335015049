import React, {Component} from 'react';
import ButtonsFooter from "../ButtonsFooter/ButtonsFooter";
import {Route, Switch} from "react-router-dom";
import MobileAppTerminalFullMenu from "./MobileAppTerminalFullMenu/MobileAppTerminalFullMenu";
import About from "./About/About";
import iOs from "./iOs/iOs";
import Android from "./Android/Android";
import Other from "./Other/Other";
import Functions from "./Functions/Functions";

class MobileAppTerminal extends Component {

    render() {
        return (
            <>
                <div className='container content_container mt-4'>
                    <Switch>
                        <Route exact path= '/mobile-app-terminal' component={MobileAppTerminalFullMenu}/>
                        <Route exact path= '/mobile-app-terminal/about' component={About}/>
                        <Route exact path= '/mobile-app-terminal/functions' component={Functions}/>
                        <Route exact path= '/mobile-app-terminal/ios' component={iOs}/>
                        <Route exact path= '/mobile-app-terminal/android' component={Android}/>
                        <Route exact path= '/mobile-app-terminal/other' component={Other}/>
                    </Switch>
                </div>
                <footer className='container footer_container'>
                    <ButtonsFooter/>
                </footer>
            </>
        )

    }
}

export default MobileAppTerminal;