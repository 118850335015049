import React, {Component, lazy, Suspense} from 'react'
import MainMenu from '../MainMenu/MainMenu';
import Header from '../Header/Header';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Preloader from '../Preloader/Preloader';
import Alert from "../Alert/Alert";
import axios from "axios";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import MobileAppTerminal from "../MobileAppTerminal/MobileAppTerminal";

const CrashTerminal = lazy(() => import('../CrashTerminal/CrashTerminal'));
const OrdersHistory = lazy(() => import('../OrdersHistory/OrdersHistory'));
const MerchantId = lazy(() => import('../MerchantId/MerchantId'));
const OrderPaper = lazy(() => import('../OrderPaper/OrderPaper'));
const ChangeCheck = lazy(() => import('../ChangeCheck/ChangeCheck'));
const Authorization = lazy(() => import('../Authorization/Authorization'));
const FinancialQuestions = lazy(() => import('../FinancialQuestions/FinancialQuestions'));
const OrderTerminal = lazy(() => import('../OrderTerminal/OrderTerminal'));
const HelpDesk = lazy(() => import('../HelpDesk/HelpDesk'));
const OtherOperations = lazy(() => import('../OtherOperations/OtherOperations'));
const SuggestionAndCommentForm = lazy(() => import('../SuggestionAndCommentForm/SuggestionAndCommentForm'));
const CheckRefund = lazy(() => import('../CheckRefund/CheckRefund'));
const PayPart = lazy(() => import('../PayPart/PayPart'));
const OrderPaperHistory = lazy(() => import('../OrderPaper/OrderPaperHistory/OrderPaperHistory'));

class App extends Component {
    componentDidMount() {
        const {authByCookie, setToken, token} = this.props;
        if (this.getCookie('token') && !token) {
            axios.post('/api/otp/check').then(response => {
                if (response.data.status === "SUCCESS") {
                    authByCookie(response.data.data);
                    setToken(this.getCookie('token'));
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {startLoadingMerchantData, setMerchantData, stopLoadingMerchantData, notAuth, showAlert, isAuth} = this.props;
        const {language} = this.props.i18n;
        if (prevProps.isAuth !== isAuth && isAuth) {
            if ( window.location.pathname === '/help-desk/terminal-settings' ) {
                return;
            }
            startLoadingMerchantData();
            axios.post('api/getMerchants')
                .then(response => {
                    if (response.data.status === "SUCCESS") {
                        setMerchantData(response.data.data);
                    }
                    if (response.data.status === "FAIL" && response.data.data.code === 110) {
                        notAuth();
                    }
                    if (response.data.status === "FAIL" && response.data.data.code !== 110) {
                        let ErrorMessage = response.data.data[language];
                        showAlert(ErrorMessage);
                        stopLoadingMerchantData();
                    }
                })
                .catch(() => {
                    let ErrorMessage = language === 'ru' ? 'Проблема c получением мерчантов, попробуйте позже' : 'Проблема c отриманням мерчантов, спробуйте пізніше';
                    this.setState({isLoadCheckList: true});
                    showAlert(ErrorMessage);
                    stopLoadingMerchantData();
                })
                .finally(() => stopLoadingMerchantData())
        }
    }

    getCookie = (name) => {
        let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : undefined;
    };

    render() {
        return (
            <BrowserRouter>
                <Header/>
                <Alert/>
                <Suspense fallback={<Preloader/>}>
                    <Switch>
                        <Route path="/" exact component={MainMenu}/>
                        <Route path="/crash" component={CrashTerminal}/>
                        <Route path="/orders-history" component={OrdersHistory}/>
                        <Route path="/order-paper/history" component={OrderPaperHistory}/>
                        <Route path="/order-paper" component={OrderPaper}/>
                        <Route path="/change-check" component={ChangeCheck}/>
                        <Route path="/financial-questions" component={FinancialQuestions}/>
                        <Route path="/order-terminal" component={OrderTerminal}/>
                        <Route path="/help-desk" component={HelpDesk}/>
                        <Route path="/authorization" component={Authorization}/>
                        <Route path="/other-operations" component={OtherOperations}/>
                        <Route path="/suggestion" component={SuggestionAndCommentForm}/>
                        <Route path="/paypart" component={PayPart}/>
                        <Route path="/check-refund" component={CheckRefund}/>
                        <Route path="/merchant-id" component={MerchantId}/>
                        <Route path="/mobile-app-terminal" component={MobileAppTerminal}/>
                    </Switch>
                </Suspense>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.usr.token,
        isAuth: state.usr.isAuth
    }
};

const mapDispatchToProps = dispatch => {
    return {
        authByCookie: (data)=> dispatch({type:"AUTHORIZED_BY_COOKIE", payload: data}),
        setToken: (token)=> dispatch({type:"SET_TOKEN", payload: token}),
        showAlert: (text) => {dispatch({type: "SHOW_ALERT", payload: text})},
        notAuth: () => {dispatch({type: "NOT_AUTHORIZED"})},
        startLoadingMerchantData: () => dispatch ({type:"START_LOADING_MERCHANT"}),
        setMerchantData: (merchantsData)=> dispatch({type:"SET_MERCHANT", payload: merchantsData}),
        stopLoadingMerchantData: () => dispatch ({type:"STOP_LOADING_MERCHANT"})
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(App));
