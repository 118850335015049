import React, {Component} from 'react';
import st from './Header.module.css';
import pb_logo from '../../img/logo.svg';
import question_logo from '../../img/question-circle.svg';
import log_out from '../../img/icon.svg'
import log_out_dis from '../../img/icon_disabled.svg'
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class Header extends Component {
    state = {
        ua: true,
        rus: false
    };

    selectedUA = () => {
        const {i18n} = this.props;
        this.setState({
            ua: true,
            rus: false
        });
        i18n.changeLanguage("ua");
    };

    logOut = () => {
        const {userLogOut, showAlertSuccess, showAlertError, i18n} = this.props;
        axios.post('/api/otp/out')
            .then(response => {
                if (response.data['status'] === 'SUCCESS') {
                    let successMessage = i18n.language === 'ru' ? 'Вы успешно вышли' : 'Ви успішно вийшли';
                    document.cookie = `token=""; max-age=-1`;
                    userLogOut();
                    showAlertSuccess(successMessage);
                }
            }).catch(() => {
            let errorMessage = i18n.language === 'ru' ? 'Ошибка при попытке выхода, попробуйте позже' : 'Помилка при спробі виходу, спробуйте пізніше';
            showAlertError(errorMessage)
        });
    };

    startPage = () => {
        this.props.history.push('/');
    };

    render() {
        const {t, isAuth} = this.props;
        const {ua, rus} = this.state;
        return (
            <div>
                <div className={st.header}>
                    <img onClick={this.startPage} src={pb_logo} alt="" className={st.logo}/>
                    <div className={st.header_title}>{t('title')}</div>
                    <div className={st.language_menu}>
                        <Popup trigger={<img className={st.log_out_active} src={question_logo} alt={t('header.info')} width="26" height="26"/>}
                               position="bottom right" on='hover' contentStyle={{ width: '25%', padding: '10px', 'margin-top': '15px' }} arrow={false}>
                            <div className={st.info_text}>
                                {t('header.help_text.part1')}<br/><br/>
                                {t('header.help_text.part2')}<br/><br/>
                                {t('header.help_text.part3')} <a className={st.out_line_none} href="https://www.youtube.com/watch?v=qE2N6fryjmY&feature=youtu.be" target="_blank">{t('header.help_text.part4')}</a>
                            </div>
                        </Popup>
                        <input defaultChecked={ua} name="language" id="UA" type="radio"/>
                        <label onClick={this.selectedUA} htmlFor="UA">{t('header.ua')}</label>
                        {isAuth ? <img className={st.log_out_active} onClick={this.logOut} src={log_out}
                                       alt="Выход"/> : <img className={st.log_out_disabled}
                                                            src={log_out_dis} alt="Выход"/>}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAuth: state.usr.isAuth
    }
};


const mapDispatchToProps = dispatch => {
    return {
        userLogOut: () => {
            dispatch({type: "USER_LOGOUT"})
        },
        showAlertSuccess: (text) => {
            dispatch({type: "SHOW_ALERT_SUCCESS", payload: text})
        },
        showAlertError: (text) => {
            dispatch({type: "SHOW_ALERT", payload: text})
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header)));
