const initialState = {
    isAuth: false,
    isPhoneSend: false,
    isOtpSend: false,
    phone: null,
    okpo: null,
    token: null
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTHORIZED':
            return {
                ...state,
                isAuth: true
            };
        case 'NOT_AUTHORIZED':
            return {
                ...state,
                isAuth: false,
                isPhoneSend:false,
                phone: null,
                okpo: null
            };
        case 'SEND_PHONE':
            return {
                ...state,
                isPhoneSend: true
            };
        case 'SEND_OTP':
            return {
                ...state,
                isOtpSend: true
            };
        case 'SET_PHONE':
            return {
                ...state,
                phone: action.payload
            };
        case 'SET_OKPO':
            return {
                ...state,
                okpo: action.payload
            };
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.payload
            };
        case 'AUTHORIZED_BY_COOKIE':
            return {
                ...state,
                isAuth: true,
                phone: action.payload.phone,
                okpo: action.payload.okpo
            };
        default:
            return state;
    }
};

export default user;