import React, {Component} from 'react';
import st from './Footer.module.css';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";
import 'reactjs-popup/dist/index.css';

class Footer extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className={st.footer}>
                <a href="https://privatbank.ua/personal-information" className={st.footer_title} target="_blank" rel="noopener noreferrer">{t('footer_title')}</a>
            </div>
        )
    }
}


export default withRouter((withTranslation()(Footer)));
