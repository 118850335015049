import React, {Component} from 'react';
import st from './Android.module.css'
import cn from 'classnames';
import {withTranslation} from 'react-i18next';
import mainStyle from "../../../css/main.module.css";
import LiWithArrow from "../../LiWithArrow/LiWithArrow";

class Android extends Component {
    state = {
        characteristics: false,
        download: false,
        registration: false,
        firstPay: false,
        instruction: false
    };

    toggleShow = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    };

    render() {
        const {characteristics, download, registration, firstPay, instruction} = this.state;
        const {t} = this.props;
        return (
            <>
                <header>
                    <div className={cn(mainStyle.header, "col-xl-6 offset-xl-3")}>
                        <span>{t('mobileApp.title')}</span>
                    </div>
                </header>

                <div className='row mt-3'>
                    <div className='col-12  col-xl-6 offset-xl-3 mb-2'>
                        {t('mobileApp.setting.android.title')}
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-xl-6 offset-xl-3'>
                        <ul className={st.terminal_list}>
                            <LiWithArrow click={this.toggleShow} elementName={"characteristics"}
                                         elementText={t("mobileApp.setting.android.characteristics.title")}/>
                            {
                                characteristics ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                1. {t('mobileApp.setting.android.characteristics.1')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                2. {t('mobileApp.setting.android.characteristics.2')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                3. {t('mobileApp.setting.android.characteristics.3')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                4. {t('mobileApp.setting.android.characteristics.4')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                5. {t('mobileApp.setting.android.characteristics.5')}
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"download"}
                                         elementText={t('mobileApp.setting.android.download.title')}/>
                            {
                                download ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                {t('mobileApp.setting.android.download.1')} - <a
                                                href="https://play.google.com/store/apps/details?id=ua.privatbank.pterminal"
                                                target="_blank" rel="noopener noreferrer">Google Play</a>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"registration"}
                                         elementText={t('mobileApp.setting.android.registration.title')}/>
                            {
                                registration ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                {t('mobileApp.setting.android.registration.1')} <a
                                                href="https://www.youtube.com/watch?v=VOzCwzpqYcw"
                                                target="_blank"
                                                rel="noopener noreferrer">{t('mobileApp.setting.android.registration.2')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"firstPay"}
                                         elementText={t('mobileApp.setting.android.firstPay.title')}/>
                            {
                                firstPay &&
                                <div className={cn(st.text, 'ml-1, mb-3')}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            {t('mobileApp.setting.android.firstPay.1')} <a
                                            href="https://youtu.be/--Ecvd_L9Cw"
                                            target="_blank"
                                            rel="noopener noreferrer">{t('mobileApp.setting.android.firstPay.2')}</a>
                                        </div>
                                    </div>
                                </div>
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"instruction"}
                                         elementText={t('mobileApp.setting.android.instruction.title')}/>
                            {
                                instruction &&
                                <div className={cn(st.text, 'ml-1, mb-3')}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            {t('mobileApp.setting.android.instruction.1')} <a
                                            href="https://privatbank.ua/business/instruction-terminal/android"
                                            target="_blank"
                                            rel="noopener noreferrer">{t('mobileApp.setting.android.instruction.2')}</a>
                                        </div>
                                    </div>
                                </div>
                            }
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Android);
