import React, {Component} from 'react';
import crash from '../../img/crash.svg'
import history from '../../img/history.svg'
import paper from '../../img/paper.svg'
import check from '../../img/check.svg'
import financial from '../../img/financial.svg'
import helpDesk from '../../img/helpdesk.svg'
import terminal from '../../img/terminal.svg'
import other from '../../img/other.svg'
import {withTranslation} from 'react-i18next'
import {Link, withRouter} from "react-router-dom";
import style from "../MainMenu/MainMenu.module.css";
import * as ReactGA from 'react-ga';
import Footer from "../Footer/Footer";

class MainMenu extends Component {

    componentDidMount() {
        ReactGA.initialize('UA-163321051-1');
        ReactGA.pageview(this.props.history.location.pathname);
        this.unlisten = this.props.history.listen(location => ReactGA.pageview(location.pathname));
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const {t } = this.props;
        return (
            <>
                        <div className='container-fluid mt-3 pb-3'>

                            <div className='row mt-3'>
                                <div className='col-6 col-xl-2 offset-xl-4'>
                                    <Link to="/crash">
                                        <button className={style.section}>
                                            <img src={crash} alt=""/>
                                            <span>{t('app.repair')}</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className='col-6 col-xl-2 '>
                                    <Link to="/orders-history">
                                        <button className={style.section}>
                                            <img src={history} alt=""/>
                                            <span>{t('app.history')}</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6 col-xl-2 offset-xl-4'>
                                    <Link to="/order-paper">
                                        <button className={style.section}>
                                            <img src={paper} alt=""/>
                                            <span>{t('app.paper')}</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className='col-6 col-xl-2'>
                                    <Link to='/change-check'>
                                        <button className={style.section}>
                                            <img src={check} alt=""/>
                                            <span>{t('app.check')}</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6 col-xl-2 offset-xl-4'>
                                    <Link to='/financial-questions'>
                                        <button className={style.section}>
                                            <img src={financial} alt=""/>
                                            <span>{t('app.financial')}</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className='col-6 col-xl-2'>
                                    <Link to='/help-desk'>
                                        <button className={style.section}>
                                            <img src={helpDesk} alt=""/>
                                            <span>{t('app.helpdesk')}</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6 col-xl-2 offset-xl-4'>
                                    <Link to='/order-terminal'>
                                        <button className={style.section}>
                                            <img src={terminal} alt=""/>
                                            <span>{t('app.terminal')}</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className='col-6 col-xl-2'>
                                    <Link to='/merchant-id'>
                                        <button className={style.section}>
                                            <span>{t('buttons.getMerchantId')}</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6 col-xl-2 offset-xl-4'>
                                    <Link to='/mobile-app-terminal'>
                                        <button className={style.section}>
                                            <span>{t('mobileApp.title')}</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className='col-6 col-xl-2'>
                                    <Link to='/other-operations'>
                                        <button className={style.section}>
                                            <img src={other} alt=""/>
                                            <span>{t('buttons.other')}</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                <Footer/>
            </>
        )
    }
}


export default withRouter(withTranslation()(MainMenu));
