import React from 'react'
import styles from './ButtonsFooter.module.css';
import back from "../../img/back-button.svg";
import other from "../../img/other.svg";
import {Link, withRouter} from "react-router-dom";
import {useTranslation} from 'react-i18next'

function buttonsFooter({ history }) {
    const {t} = new useTranslation();
    const goBack = () => {
        clearChat();
        if (history.length > 2) {
            history.goBack();
        } else {
            history.push('/');
        }
    };
    const clearChat = () => {
        if (document.getElementById('ch-widget-container')) {
            document.getElementById('ch-widget-container').remove();
        }
    };

    return (
        <footer className='mt-4 mb-3'>
            <div className='row  justify-content-center'>
                <div className='col-6 col-xl-3 col-md-4'>
                    <button onClick={goBack} className={styles.button_back}>
                        <img src={back} alt=""/>
                        <span>{t('buttons.back')}</span>
                    </button>
                </div>
                <div className='col-6 col-xl-3 col-md-4'>
                    <Link to='/'>
                        <button onClick={clearChat} className={styles.button_next}>
                            <span>{t('buttons.other')}</span>
                            <img src={other} alt=""/>
                        </button>
                    </Link>
                </div>
            </div>
        </footer>
    )
}

export default withRouter(buttonsFooter);
