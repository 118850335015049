import React, {Component} from 'react';
import st from './Alert.module.css';
import {connect} from "react-redux";
import cn from 'classnames';

class Alert extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {showAlert,dontCloseAlert} = this.props;
        if (showAlert && dontCloseAlert === false) {
            this.timer = setTimeout(() => {
                this.props.closeAlert();
            }, 7000)
        }
    }

    closeAlert = () => {
        this.props.closeAlert();
        clearTimeout(this.timer);
    };

    render() {
        const {textAlert, showAlert, alertClass} = this.props;
        return (
            <>
                {
                    showAlert ?
                        <section
                            className={cn(alertClass, 'alert')}
                            onClick={this.closeAlert}>
                            <span>{textAlert}</span>
                            <span className={st.close}>×</span>
                        </section>
                        : null
                }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        alertClass: state.alert.alertClass,
        showAlert: state.alert.showAlert,
        textAlert: state.alert.text,
        dontCloseAlert: state.alert.dontCloseAlert

    }
};

const mapDispatchToProps = dispatch => ({
    closeAlert: () => {dispatch({type: "HIDE_ALERT"})}
});


export default connect(mapStateToProps,mapDispatchToProps)(Alert);