import React, {Component} from 'react';
import st from './Other.module.css'
import cn from 'classnames';
import {withTranslation} from 'react-i18next';
import mainStyle from "../../../css/main.module.css";
import LiWithArrow from "../../LiWithArrow/LiWithArrow";

class Other extends Component {
    state = {
        subscriptionFee: false,
        prro: false,
        fiscalization: false,
        doReturn: false,
        paperCheck: false,
        reports: false,
        seeReports: false
    };

    toggleShow = (name) => {
        this.setState({
            [name]: !this.state[name]
        })
    };
    
    render() {
        const {subscriptionFee, prro, fiscalization, doReturn, paperCheck, reports, seeReports} = this.state;
        const {t} = this.props;
        return (
            <>
                <header>
                    <div className={cn(mainStyle.header, "col-xl-6 offset-xl-3")}>
                        <span>{t('mobileApp.title')}</span>
                    </div>
                </header>

                <div className='row mt-3'>
                    <div className='col-12  col-xl-6 offset-xl-3 mb-2'>
                        {t('mobileApp.about.title')}
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-xl-6 offset-xl-3'>
                        <ul className={st.terminal_list}>
                            <LiWithArrow click={this.toggleShow} elementName={"subscriptionFee"}
                                         elementText={t("mobileApp.other.subscriptionFee.title")}/>
                            {
                                subscriptionFee ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                {t('mobileApp.other.subscriptionFee.1')}
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"prro"}
                                         elementText={t('mobileApp.other.prro.title')}/>
                            {
                                prro ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                {t('mobileApp.other.prro.1')}
                                            </div>
                                        </div>
                                    </div>
                                : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"fiscalization"}
                                         elementText={t('mobileApp.other.fiscalization.title')}/>
                            {
                                fiscalization ?
                                    <div className={cn(st.text, 'ml-1, mb-3')}>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                1. {t('mobileApp.other.fiscalization.1.1')} <a
                                                href="https://otp24.privatbank.ua/?go_to=76&_gl=1*r79g69*_ga*MTA5MTA1OTc0MC4xNzA3OTc1NzEw*_ga_C7N2L9YCQ9*MTcwODMzMDk3Mi40LjEuMTcwODMzMDk3OC41NC4wLjA."
                                                target="_blank"
                                                rel="noopener noreferrer">{t('mobileApp.other.fiscalization.1.2')}</a> {t('mobileApp.other.fiscalization.1.3')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                            2. {t('mobileApp.other.fiscalization.2')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                3. {t('mobileApp.other.fiscalization.3')}
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-12'>
                                                4. {t('mobileApp.other.fiscalization.4')}
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"doReturn"}
                                         elementText={t('mobileApp.other.doReturn.title')}/>
                            {
                            doReturn &&
                                <div className={cn(st.text, 'ml-1, mb-3')}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            1. {t('mobileApp.other.doReturn.1')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            2. {t('mobileApp.other.doReturn.2')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            3. {t('mobileApp.other.doReturn.3')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            4. {t('mobileApp.other.doReturn.4')}
                                        </div>
                                    </div>
                                </div>
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"paperCheck"}
                                         elementText={t('mobileApp.other.paperCheck.title')}/>
                            {
                                paperCheck &&
                                <div className={cn(st.text, 'ml-1, mb-3')}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            {t('mobileApp.other.paperCheck.1')}
                                        </div>
                                    </div>
                                </div>
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"reports"}
                                         elementText={t('mobileApp.other.reports.title')}/>
                            {
                                reports &&
                                <div className={cn(st.text, 'ml-1, mb-3')}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            <strong>{t('mobileApp.other.reports.1.title')}</strong> - {t('mobileApp.other.reports.1.1')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            <strong>{t('mobileApp.other.reports.2.title')}</strong> - {t('mobileApp.other.reports.2.1')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            <strong>{t('mobileApp.other.reports.3.title')}</strong> - {t('mobileApp.other.reports.3.1')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            <strong>{t('mobileApp.other.reports.4.title')}</strong> - {t('mobileApp.other.reports.4.1')}
                                        </div>
                                    </div>
                                </div>
                            }
                            <LiWithArrow click={this.toggleShow} elementName={"seeReports"}
                                         elementText={t('mobileApp.other.seeReports.title')}/>
                            {
                                seeReports &&
                                <div className={cn(st.text, 'ml-1, mb-3')}>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            1. {t('mobileApp.other.seeReports.1')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            2. {t('mobileApp.other.seeReports.2')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            3. {t('mobileApp.other.seeReports.3')}
                                        </div>
                                    </div>
                                    <div className='row mb-2'>
                                        <div className='col-12'>
                                            4. {t('mobileApp.other.seeReports.4')}
                                        </div>
                                    </div>
                                </div>
                            }
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Other);
