const initialState = {
    problemsList: [],
    isLoaded: false,
    showPreload: false
};

const trackerProblems = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TRACK_PROBLEM':
            return {
                ...state,
                problemsList: action.payload,
                showPreload:false,
                isLoaded:true
            };
        case 'CLOSE_PRELOADER':
            return {
                ...state,
                showPreload:false,
                isLoaded:true
            };
        case 'OPEN_PRELOADER':
            return {
                ...state,
                showPreload:true,
            };
        default:
            return state;
    }
};

export default trackerProblems;