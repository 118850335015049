const initialState = {
    text: 'Error',
    showAlert: false,
    dontCloseAlert: false
};

const alert = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_ALERT':
            return {
                ...state,
                showAlert: true,
                alertClass:"alert_error",
                text: action.payload
            };
        case 'HIDE_ALERT':
            return {
                ...state,
                text: 'Error',
                showAlert:false,
                dontCloseAlert:false
            };
        case 'SHOW_AND_DONT_HIDE_ALERT':
            return {
                ...state,
                showAlert: true,
                alertClass:"alert_error",
                dontCloseAlert: true,
                text: action.payload
            };
        case 'SHOW_ALERT_SUCCESS':
            return {
                ...state,
                showAlert: true,
                alertClass: "alert_success",
                text: action.payload
            };
        default:
            return state;
    }
};

export default alert;