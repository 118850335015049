import React, {Component} from 'react';
import st from './Functions.module.css'
import cn from 'classnames';
import {withTranslation} from 'react-i18next';
import mainStyle from "../../../css/main.module.css";
import * as PropTypes from "prop-types";

function Checkbox(props) {
    return null;
}

Checkbox.propTypes = {name: PropTypes.string};

class Functions extends Component {

    render() {
        const {t} = this.props;
        return (
            <>
                <header>
                    <div className={cn(mainStyle.header, "col-xl-6 offset-xl-3")}>
                        <span>{t('mobileApp.title')}</span>
                    </div>
                </header>

                <div className='row mt-3'>
                    <div className='col-12  col-xl-6 offset-xl-3 mb-2'>
                        {t('mobileApp.functions.title')}
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-xl-6 offset-xl-3'>
                        <ul className={st.terminal_list}>
                            <div className={cn(st.text, 'ml-1, mb-3')}>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <input type="checkbox" disabled />
                                        &nbsp;&nbsp;{t('mobileApp.functions.text_1')}
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <input type="checkbox" disabled/>
                                        &nbsp;&nbsp;{t('mobileApp.functions.text_2')}
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <input type="checkbox" disabled/>
                                        &nbsp;&nbsp;{t('mobileApp.functions.text_3')}
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <input type="checkbox" disabled/>
                                        &nbsp;&nbsp;{t('mobileApp.functions.text_4')}
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <input type="checkbox" disabled/>
                                        &nbsp;&nbsp;{t('mobileApp.functions.text_5')}
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <input type="checkbox" disabled/>
                                        &nbsp;&nbsp;{t('mobileApp.functions.text_6')}
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Functions);
